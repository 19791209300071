@font-face {
  font-family: Control Upright Web;
  src: url("../ControlUpright-VF-Web.1ff753ca.woff2") format("woff2"), url("../ControlUpright-VF-Web.5538da4d.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Control Upright MediumTNT;
  src: url("../ControlUpright-MediumTNT-Web.7baabdc9.woff2") format("woff2"), url("../ControlUpright-MediumTNT-Web.1b106df2.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

html, body {
  -webkit-font-smoothing: antialiased;
  background: #00007806;
  margin: 0;
  padding: 0;
  font-family: Control Upright Web;
  overflow-x: hidden;
}

body {
  transition: background-color 1.25s;
}

body.background-light {
  background: #00007806;
}

body.background-gray {
  background-color: #787878;
}

body.background-black {
  color: #fefefe;
  background-color: #181818;
}

h1, h2, h3, h4, h5, h6 {
  font-feature-settings: "ss09" 1, "ss08" 1;
  text-align: center;
  margin: .125em 0;
  font-family: Control Upright MediumTNT;
  font-size: max(42px, 4vw);
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  transition: color 1.25s;
}

@media (max-width: 640px) {
  h1, h2, h3, h4, h5, h6 {
    padding: 0 .25em;
  }
}

p {
  font-size: 10px;
  transition: color 1.25s;
}

a {
  color: #000;
}

a:hover, a.big {
  text-decoration: none;
}

a.big:hover {
  color: #fff;
}

a.big h4 {
  transition: none;
}

header {
  align-items: center;
  padding: 0;
  display: grid;
}

.introduction {
  text-align: center;
}

.introduction h1, .introduction span {
  display: inline-block;
}

.introduction span {
  font-size: 10px;
}

@media (max-width: 640px) {
  header {
    padding: .75em 0 .5em;
  }

  .introduction span {
    display: none;
  }
}

img, video {
  max-width: 100%;
  height: auto;
  backface-visibility: hidden;
  border-radius: 4px;
  display: block;
}

img.hard {
  border-radius: 0;
}

main {
  width: 100%;
  box-sizing: border-box;
  perspective: 1000px;
  flex-direction: column;
  gap: 1px;
  margin: 0;
  padding: 0 1.5vw 1.5vw;
  display: flex;
}

.item {
  margin: 0;
  padding: 0;
}

.single, .outro {
  justify-content: center;
  align-items: center;
  display: grid;
}

a:hover figure figcaption {
  text-decoration: underline;
}

figure {
  will-change: transform;
  margin: 0;
  padding: 0;
  transition: transform .25s ease-out;
  position: relative;
}

figure.tiny img, figure.tiny video {
  width: 25vh;
  height: auto;
}

figure.small img, figure.small video {
  width: 50vh;
  height: auto;
}

figure.medium img, figure.medium video {
  width: 75vh;
  height: auto;
}

figcaption {
  box-sizing: border-box;
  mix-blend-mode: difference;
  color: #fff;
  opacity: .8;
  padding: 1.5em;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

figcaption.black {
  mix-blend-mode: normal;
  color: #000;
}

@media (max-width: 640px) {
  figcaption {
    padding: 1em;
  }
}

.double {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 1px;
  display: grid;
}

.double > a {
  display: contents;
}

.double > a:first-child figure, .double > figure:first-child {
  justify-self: end;
}

.double > a:last-child figure, .double > figure:last-child {
  justify-self: start;
}

.v-top {
  align-items: start;
}

.v-center {
  align-items: center;
}

.v-bottom {
  align-items: end;
}

.collaboration {
  width: 35vw;
  height: 27.5vw;
  background: #fff;
  border-radius: 8px;
  place-items: center;
  display: grid;
}

@media (max-width: 640px) {
  .collaboration {
    width: 80vw;
    height: 80vw;
    border-radius: 32px;
  }
}

.collaboration figcaption {
  width: 100%;
  text-align: center;
  padding: 2em;
}

.collaboration a {
  color: #fff;
}

.logotype {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.collaboration img {
  width: 35%;
  height: auto;
  transform: translateY(-5%);
}

.outro {
  color: #fff;
  height: 100vh;
  text-align: center;
}

#slideshow-caption {
  max-width: 68ch;
  text-align: left;
  color: #fc0;
  text-shadow: 1px 1px #000, -1px -1px #000, -1px 1px #000, 1px -1px #000;
  background: #00000080;
  border-radius: 4px;
  padding: .75rem;
  font-size: 22px;
  position: absolute;
  bottom: 2.5em;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 640px) {
  .min-height {
    min-height: 5em;
  }
}

/*# sourceMappingURL=index.d8b44af4.css.map */
