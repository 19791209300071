@font-face {
  font-family: "Control Upright Web";
  src: url("fonts/ControlUpright-VF-Web.woff2") format("woff2"),
	   url("fonts/ControlUpright-VF-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Control Upright MediumTNT";
  src: url("fonts/ControlUpright-MediumTNT-Web.woff2") format("woff2"),
	   url("fonts/ControlUpright-MediumTNT-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Control Upright Web";
  -webkit-font-smoothing: antialiased;
  background: rgba(0, 0, 120, 0.025);
  overflow-x: hidden;
}

body {
  transition: background-color 1.25s ease;
}

body.background-light {
  background: rgba(0, 0, 120, 0.025);
}

body.background-gray {
  background-color: rgba(120, 120, 120, 1);
}

body.background-black {
  background-color: rgba(24, 24, 24, 1);
  color: #fefefe;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Control Upright MediumTNT";
  font-style: normal;
  font-size: max(42px, 4vw);
  line-height: 1;
  font-weight: 500;
  font-feature-settings: "ss09" 1, "ss08" 1;
  text-align: center;
  margin: 0.125em 0;
  transition: color 1.25s ease;
}

@media (max-width: 640px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
	padding: 0 0.25em;
  }
}

p {
  font-size: 10px;
  transition: color 1.25s ease;
}

a {
  color: black;
}

a:hover {
  text-decoration: none;
}

a.big {
  text-decoration: none;
}

a.big:hover {
  color: white;
}

a.big h4 {
  transition: none;
}

header {
  display: grid;
  align-items: center;
  padding: 0;
}

.introduction {
  text-align: center;
}

.introduction h1,
.introduction span {
  display: inline-block;
}

.introduction span {
  font-size: 10px;
}

@media (max-width: 640px) {
  header {
	padding: 0.75em 0 0.5em 0;
  }
  .introduction span {
	display: none;
  }
}

img,
video {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
  backface-visibility: hidden;
}

img.hard {
  border-radius: 0;
}

main {
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 100%;
  margin: 0;
  padding: 0 1.5vw 1.5vw 1.5vw;
  box-sizing: border-box;
  perspective: 1000px;
}

.item {
  margin: 0;
  padding: 0;
}

.single,
.outro {
  display: grid;
  justify-content: center;
  align-items: center;
}

a:hover figure figcaption {
  text-decoration: underline;
}

figure {
  margin: 0;
  padding: 0;
  position: relative;
  transition: transform 0.25s ease-out;
  will-change: transform;
}

figure.tiny img,
figure.tiny video {
  width: 25vh;
  height: auto;
}

figure.small img,
figure.small video {
  width: 50vh;
  height: auto;
}

figure.medium img,
figure.medium video {
  width: 75vh;
  height: auto;
}
figcaption {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1.5em;
  font-size: 10px;
  mix-blend-mode: difference;
  color: white;
  opacity: 0.8;
}
figcaption.black {
  mix-blend-mode: normal;
  color: black;
}
@media (max-width: 640px) {
	figcaption {
		padding: 1em;
	}
}

.double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1px;
  justify-content: center;
}

.double > a {
  display: contents;
}

.double > a:first-child figure,
.double > figure:first-child {
  justify-self: end;
}

.double > a:last-child figure,
.double > figure:last-child {
  justify-self: start;
}

.v-top {
  align-items: start;
}

.v-center {
  align-items: center;
}

.v-bottom {
  align-items: end;
}

.collaboration {
  width: 35vw;
  height: 27.5vw;
  background: white;
  display: grid;
  place-items: center;
  border-radius: 8px;
}

@media (max-width: 640px) {
  .collaboration {
	width: 80vw;
	height: 80vw;
	border-radius: 32px;
  }
}

.collaboration figcaption {
  padding: 2em;
  width: 100%;
  text-align: center;
}

.collaboration a {
  color: white;
}

.logotype {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.collaboration img {
  width: 35%;
  height: auto;
  transform: translateY(-5%);
}

.outro {
  color: white;
  height: 100vh;
  text-align: center;
}

#slideshow-caption {
  position: absolute;
  bottom: 2.5em;
  left: 50%;
  transform: translateX(-50%);
  max-width: 68ch;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  color: rgb(255, 204, 0);
  font-size: 22px;
  padding: 0.75rem;
  border-radius: 4px;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;
}

@media (max-width: 640px) {
  .min-height {
	min-height: 5em;
  }
}